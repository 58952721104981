<template>
  <div>
    <div v-for="edp in edps" :key="edp.id" class="d-flex align-items-center">
      <button-circle
        v-if="edp.id !== value"
        @click="$emit('input', edp.id)"
        :loading="loading"
        name="circle"
        size="18"
      ></button-circle>
      <button-circle v-if="edp.id === value" :loading="loading" name="check-circle" size="18"></button-circle>
      <span class="ml-2">{{ edp.description }}</span>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { ButtonCircle },
  data() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    value: {
      Type: [Number, String],
      Requierd: false
    },
    affaireId: {
      Type: Object,
      Requierd: false
    },
    data: {
      Type: [Array, Object],
      Requierd: false
    },
    errors: {
      Type: [Array, Object],
      Requierd: false
    }
  },
  computed: {
    edps() {
      let rep = [];
      this.data.forEach(affaire => {
        if (affaire.id === this.affaireId)
          affaire.affaire_edp.forEach(edp => {
            rep.push(edp);
          });
      });
      return rep;
    }
  },
  methods: {}
};
</script>
<style lang="css"></style>
