<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Doses" subtitle="Liste des doses enregistrées">
      <button class="btn btn-primary" v-tooltip="'Ajouter une dose'" @click="createDose(id)">Ajouter</button>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div
          class="d-flex flex-column flex-md-row align-items-center p-4 rounded w-100 ml-auto mr-auto mb-1"
          v-background-1
        >
          <div class="ml-3 text-nowrap">
            <div>
              <small>cumul 12 mois (mSv)</small>
            </div>
            <h2 class="m-0">{{ cumul12mois.toFixed(3) }}</h2>
          </div>
        </div>
        <div class="mb-3">
          <div class="alert" v-background-1>{{ $moment().format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN && dosesInN.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(1, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN1" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN1 && dosesInN1.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(2, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN2" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN2 && dosesInN2.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(3, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN3" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN3 && dosesInN3.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(4, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN4" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN4 && dosesInN4.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(5, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN5" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN5 && dosesInN5.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(6, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN6" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN6 && dosesInN6.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(7, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN7" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN7 && dosesInN7.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(8, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN8" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN8 && dosesInN8.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(9, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN9" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN9 && dosesInN9.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(10, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN10" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN10 && dosesInN10.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(11, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN11" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN11 && dosesInN11.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
        <div class="mb-3">
          <div
            class="alert"
            v-background-1
          >{{ $moment().subtract(12, "months").format('MMMM YYYY') }}</div>
          <div v-for="dose in dosesInN12" :key="dose.id" class="w-100">
            <dose-item :dose="dose" :lists="lists" :affaires="affaires" @update="CalculRecap"></dose-item>
          </div>
          <div class="text-muted" v-if="dosesInN12 && dosesInN12.length === 0">
            <small>Aucune dose</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import DoseItem from "@/components/collaborateurs/profil/forms/dosimetrie/DosimetrieItem.vue";
//import Trend from "vuetrend";

export default {
  components: {
    BaseToolBar,
    DoseItem
    //Trend
  },
  data() {
    return {
      lists: {},
      feedback: {},
      months: [],
      cumul12mois: 0
    };
  },
  props: {},
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id"]),
    ...mapMultiRowFields("dosimetrie", ["doses"]),
    ...mapMultiRowFields("affaires", ["affaires"]),

    dosesInN() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment().format("MM-YYYY")
      );
    },
    dosesInN1() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(1, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN2() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(2, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN3() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(3, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN4() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(4, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN5() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(5, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN6() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(6, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN7() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(7, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN8() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(8, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN9() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(9, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN10() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(10, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN11() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(11, "months")
            .format("MM-YYYY")
      );
    },
    dosesInN12() {
      return this.doses.filter(
        dose =>
          this.$moment(dose.dosi_date_fin).format("MM-YYYY") ===
          this.$moment()
            .subtract(12, "months")
            .format("MM-YYYY")
      );
    }
  },
  methods: {
    ...mapActions({
      _createDose: "dosimetrie/create",
      getAffaires: "affaires/getAffairesPcr"
    }),
    createDose: function() {
      this.feedback = {};
      this._createDose({
        dosi_affaire_id: null,
        dosi_collaborateur_id: this.id
      }).catch(error => this.errHandler(error));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    CalculRecap: function() {
      let dateStart = this.$moment().subtract(11, "months");
      let dateEnd = this.$moment();
      let timeValues = [];
      let _dose = 0;
      let _cumul = 0;

      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        _dose = 0;
        for (let index = 0; index < this.doses.length; index++) {
          const dose = this.doses[index];
          if (
            this.$moment(dose.dosi_date_fin).format("YYYY-MM") ===
            dateStart.format("YYYY-MM")
          ) {
            if (dose.dosi_dose) {
              _dose = _dose + parseFloat(dose.dosi_dose);
              _cumul = _cumul + parseFloat(dose.dosi_dose);
            }
          }
        }
        if (_dose === 0) {
          timeValues.push({
            month: dateStart.format("YYYY-MM"),
            value: 0,
            cumul: parseFloat(_cumul)
          });
        } else {
          timeValues.push({
            month: dateStart.format("YYYY-MM"),
            value: parseFloat(_dose),
            cumul: parseFloat(_cumul)
          });
        }
        dateStart.add(1, "month");
      }
      this.months = timeValues;
      this.cumul12mois = _cumul;
    }
  },
  mounted() {
    this.getAffaires();
    this.CalculRecap();
  }
};
</script>
