<template>
  <container-list-extend title="Dose">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 v-if="dose.dosi_dose === 0" class="m-0 ml-1">0.000 mSv</h6>
        <h6 v-if="dose.dosi_dose === null" class="m-0 ml-1 text-primary">Nouvelle dose</h6>
        <h6 v-if="dose.dosi_dose > 0" class="m-0 ml-1">{{ dose.dosi_dose.toFixed(3) }} mSv</h6>
        <description-from-list
          class="ml-2 text-muted"
          :value="dose.dosi_affaire_id"
          :data="affaires"
          text="affaire ?"
          field="affaire_identifiant"
        ></description-from-list>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="dose.dosi_date_debut">
            Le :
            {{ $moment(dose.dosi_date_debut).format("DD/MM/YYYY") }}
          </div>
          <div
            v-if="
              dose.dosi_date_fin &&
              $moment(dose.dosi_date_debut).format('DD/MM/YYYY') !== $moment(dose.dosi_date_fin).format('DD/MM/YYYY')
            "
          >
            Au :
            {{ $moment(dose.dosi_date_fin).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form deleteBtn @click="updateDose(dose)" @delete="deleteDose(dose)" :loading="loading">
        <base-form-row row>
          <base-inputDatePicker
            v-model="dose.dosi_date_debut"
            inputText="Date (début d'exposition)"
            :errors="feedback.dosi_date_debut"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="dose.dosi_date_fin"
            inputText="Date (fin d'exposition)"
            :errors="feedback.dosi_date_fin"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-number
            v-model="dose.dosi_dose"
            inputText="Dose (mSv)"
            :errors="feedback.dosi_dose"
            :step="1"
          ></base-input-number>
          <base-select
            inputText="Type"
            v-model.number="dose.dosi_type_id"
            :options="data.typesDosimetrie"
            :errors="feedback.dosi_type_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Affaire"
            v-model.number="dose.dosi_affaire_id"
            :options="affaireData"
            :errors="feedback.dosi_affaire_id"
            field="lib"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <dosimetrie-item-edp
            v-model="dose.dosi_edp_id"
            :affaireId="dose.dosi_affaire_id"
            :data="affaires"
            :errors="feedback.dosi_edp_id"
          ></dosimetrie-item-edp>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="dose.dosi_commentaire"
            text="Commentaire"
            :errors="feedback.dosi_commentaire"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import DosimetrieItemEdp from "@/components/collaborateurs/profil/forms/dosimetrie/DosimetrieItemEdp.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseSelect,
    BaseInputNumber,
    DosimetrieItemEdp,
    DescriptionFromList,
  },
  data() {
    return {
      feedback: {},
      loading: false,
    };
  },
  props: {
    dose: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    affaires: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    affaireData() {
      return this.affaires.map((affaire) => {
        return {
          id: affaire.id,
          lib: affaire.affaire_identifiant + " " + affaire.affaire_libelle,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      _updateDose: "dosimetrie/update",
      _deleteDose: "dosimetrie/delete",
    }),
    updateDose: function (dose) {
      this.feedback = {};
      this.loading = true;
      this._updateDose(dose)
        .then(() => {
          this.$emit("update");
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteDose: function (dose) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteDose(dose)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    collabSelected: function (collab) {
      this.dose.collaborateur = collab;
      //this.dose.dosi_collab_id = collab.id;
    },
  },
};
</script>
<style lang="css"></style>
